.weather-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 400;
  white-space: nowrap;
}

.map-custom-popup .leaflet-popup-content-wrapper {
  background-color: #363640;
  color: #fff;
}
.map-custom-popup .leaflet-popup-tip {
  background-color: #363640;
}
.map-custom-popup .leaflet-popup-content-wrapper p {
  margin: 3px;
}
.map-custom-popup .leaflet-popup-content-wrapper p span {
  display: inline-block;
  text-align: center;
  width: 20px;
}
.map-custom-popup .leaflet-popup-content-wrapper p.title {
  font-size: 1.2em;
  margin-top: 3px;
  margin-bottom: 7px;
}

.map-custom-popup .leaflet-popup-close-button {
  color: #fff;
}

.tooltipxs {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: white;
  background-color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
