body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dx-toast-content {
  justify-content: center;
}

#map {
  /* height: 180px; */
}

.leaflet-container {
  height: 75vh;
  width: 100%;
  margin-left: auto;
}
.state-tooltip {
  margin: 10px;
}
.state-tooltip .title {
  margin: 15px 0;
  font-size: 1.2em;
}
.state-tooltip .singleInfo {
  margin: 2px 0;
}

.bpkd_reponsive_box .dx-item-content,
.bpkd_reponsive_box .dx-box-flex {
  gap: 20px;
}

.data-grid-plus-icon {
  min-width: 20px !important;
  width: 20px !important;
  height: 20px !important;
}
